<template>
  <div class="flex-1 mt-1 bg-gray-100 px-4 pt-12 mb-20  items-center">
    <div class="bg-white w-fullrounded-lg shadow">
      <div class="h-20 py-3 flex items-center border-gray-200 p-6 mt-2">
        <div class="flex">
          <button
            :class="$colors.primary"
            class="rounded-full flex justify-center items-center w-8 h-8 mr-3"
            @click="RouterBack()"
          >
            <i class="material-icons">chevron_left</i>
          </button>
          <div v-if="!formData._id" class="text-2xl font-bold text-green-500">New Sourcing
          </div>
          <div v-else class="text-2xl font-bold text-green-500">Edit Sourcing
          </div>
        </div>
      </div>

      <div class="">
        <div class="bg-white rounded-lg p-6 pt-2">
          <div>
            <!-- ************************************************************************************ -->
            <!-- ************************************Select Colis***********************************-->
            <div class="px-6 mt-3">
              <div class="flex w-full space-x-2">
                <div class="flex w-1/2 justify-between shadow-xs items-center h-auto p-6 my-1 mb-1 rounded-lg">
                  <div class="flex w-full">
                    <span class="rounded-full h-12 w-12 bg-gray-200">
                      <i class="material-icons p-3 text-green-400">person</i>
                    </span>

                    <div class="ml-5 w-full">
                      <div class="text-base mt-1 font-semibold text-gray-600 pb-10">
                        Country information
                      </div>
                      <div class="text-sm font-light text-gray-500"></div>
                      <div class="w-full space-y-4">
                      
                        <div
                          class="border hover:border-green-400 w-full focus-within:border-green-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
                          <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                            <p>
                              <label for="customerPhone" class="bg-white text-gray-700 px-1">Pays d'achat
                                <span :class="$colors.required">*</span></label>
                            </p>
                          </div>
                          <p>
                            <v-select @input="setSelected" v-model="formData.country" :options="options" label="name">
                                <template slot="option" slot-scope="option">
                                    <img class="flag-img" :src="$f.getCountryCode(option.code)"/>
                                    {{ option.name }} 
                                </template>
                            </v-select>
                          </p>
                        </div>
                        <div :class="!formData._id && !formData.warehouse?'animation-pulse':''"
                          class="border hover:border-green-400 w-full focus-within:border-green-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
                          <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                            <p>
                              <label for="customerPhone" class="bg-white text-gray-700 px-1">Pays warehousing
                                <span :class="$colors.required">*</span></label>
                            </p>
                          </div>
                          <p>
                           
                            <v-select @input="setSelectedW" v-model="formData.warehouse" :options="warehouses" label="name">
                                <template slot="option" slot-scope="option">
                                    <img class="flag-img" :src="$f.getCountryCode(option.country)"/>
                                    {{ option.name }} 
                                </template>
                            </v-select>
                          </p>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                <!-- ****************************** Order Information *********************************************-->

                <div class="flex w-1/2 justify-between shadow-xs items-center h-auto p-6 my-1 mb-1 rounded-lg">
                  <div class="flex w-full mt-2">
                    <span class="rounded-full h-12 w-12 bg-gray-200">
                      <i class="material-icons p-3 text-green-400">local_grocery_store</i>
                    </span>

                    <div class="ml-5 w-full">
                      <div class="text-base mt-1 font-semibold text-gray-600 pb-10">
                        Produit informations
                      </div>
                      <div class="text-sm font-light text-gray-500"></div>
                      <div class="w-full space-y-4">
                        <div class="border hover:border-green-500  w-full focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
                          <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                            <p>
                              <label for="customerShippingAddress" class="bg-white text-gray-700 px-1">{{$t('transport_mode')}}
                                <span :class="$colors.required">*</span></label>
                            </p>
                          </div>
                          <p>
                            <select id="type" name="seller" autocomplete="type" v-model="formData.provider.transportMode"
                              class="py-2 text-xs capitalize px-2 outline-none block h-full w-full bg-white">
                              <option selected disabled :value="null">{{$t('transport_mode')}}</option>
                              <option v-for="(trans, index) in transports" :key="index" :value="trans">
                                {{ trans }}
                              </option>
                            </select>
                          </p>
                        </div>
                        <!-- <div  class="border hover:border-green-500  w-full focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
                            <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                              <p>
                                <label for="carrier" class="bg-white text-gray-700 px-1">  {{$t('Nom de Fournisseur')}}
                                  <span :class="$colors.required">*</span></label>
                              </p>
                            </div>
                            <p>
                              <input id="carrier" autocomplete="false" tabindex="0" type="text" 
                                v-model="formData.provider.fournisseur"
                                class="py-2 text-xs px-2 outline-none block h-full w-full" />
                            </p>
                          </div>
                          <div  class="border hover:border-green-500  w-full focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
                            <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                              <p>
                                <label for="carrier" class="bg-white text-gray-700 px-1">  {{$t('Nom de Transitaire')}} <span :class="$colors.required">*</span></label>
                              </p>
                            </div>
                            <p>
                              <input id="carrier" autocomplete="false" tabindex="0" type="text" 
                                v-model="formData.provider.transitaire"
                                class="py-2 text-xs px-2 outline-none block h-full w-full" />
                            </p>
                          </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Status and seller Administartion -->
              <div class="bg-white rounded-lg p-6">
                  <div class="grid lg:grid-cols-2 gap-6">
                      <div v-if="formData.warehouse && currentUser.type !== 'Seller'"  class="border seller-select h-10 hover:border-green-400 focus-within:border-green-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
                        <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                          <p>
                            <label for="seller" class="bg-white text-gray-700 px-1"> Seller
                            <span :class="$colors.required">*</span></label>
                          </p>
                        </div>    
                        <p>
                            <v-select label="fullName" @search="getUsersDebance($event,'shipsen')" v-model="formData.seller" :options="sellers"></v-select>
                        </p>
                        
                      </div>  
                      <div v-if="formData._id" class="border seller-select h-10 hover:border-green-400 focus-within:border-green-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
                        <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                          <p>
                            <label for="seller" class="bg-white text-gray-700 px-1">Status
                            <span :class="$colors.required">*</span></label>
                          </p>
                        </div>
                        <p>
                            <v-select :disabled="currentUser.type === 'Seller'?true:false"   v-model="formData.status" :options="status"></v-select>
                        </p>
                      </div> 
                      <!-- <div class="border hover:border-green-400 w-full focus-within:border-green-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
                          <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                              <p>
                                  <label for="dateexpar" class="bg-white text-gray-700 px-1">Facturation date
                                      <span :class="$colors.required">*</span></label>
                              </p>
                          </div>
                          <p>
                                <datetime-v2 type="datetime" v-model="formData.facturationDate"
                                        placeholder="FacturationDate date ..." input-class="py-1 text-xs px-2 outline-none block h-full w-full" ></datetime-v2> 
                          </p>
                      </div> -->
                  </div>  
              </div>     

              <!-- ********************************************************Product Table assigned to expedition **********************************-->
              <div v-if="formData.seller && formData.warehouse" class="flex w-full">
                <div class="flex space-x-2 w-full shadow-xs mt-1 rounded-lg">
                  <div class="flex w-full justify-between items-center h-auto p-6">
                    <div class="flex w-full">
                      <span class="rounded-full h-12 w-12 bg-gray-200">
                        <i class="material-icons p-3 text-green-500">local_mall</i>
                      </span>
                      <div class=" w-full p-2 pb-8">
                        <div class="w-full  flex text-base font-semibold text-gray-600">
                          <p class="w-1/2">Products</p>
                          <div class="w-full mr-10">
                            <button :disabled="currentUser.type === 'Seller' && formData._id && formData.status !=='pending'  ? true : false" @click="OpenPopUp"  :class="formData.details.length===0?'animation-pulse':''"
                              class="flex text-white mx-auto max-w-sm rounded-md text-center bg-green-500 py-2 px-8 items-center focus:outline-none md:float-right">
                              Select Product
                            </button>
                          </div>
                        </div>

                        <div v-if="formData.details" class="my-4 py-4 mr-10  overflow-x-auto">
                           <h4 class="other-fees third uppercase">Products</h4>
                          <table class="min-w-full table item leading-normal">
                            <thead>
                              <tr>
                                <th
                                  class="px-8 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">
                                  ID
                                </th>
                                <th
                                  class="px-8 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">
                                  Image
                                </th>
                                <th
                                  class="px-8 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">
                                  Name
                                </th>

                                <th
                                  class="px-8 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">
                                  Category
                                </th>

                                <th
                                  class="px-8 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">
                                  Quantity
                                </th>
                                <th
                                  class="px-8 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">
                                  Lien De Produit 
                                </th>
                               
                                 <th v-if="(formData._id && currentUser.type === 'Seller') || (currentUser.type !== 'Seller')"
                                  class="px-8 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">
                                  Poids <sup>(KG)</sup>
                                </th>

                                 <th v-if="(formData._id && currentUser.type === 'Seller') || (currentUser.type !== 'Seller')"
                                  class="px-8 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">
                                  Unit Price <sup>(MAD)</sup>
                                </th>

                                 <th v-if="(formData._id && currentUser.type === 'Seller') || (currentUser.type !== 'Seller')"
                                  class="px-8 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">
                                  Unit Price <sup v-if="formData.warehouse">({{formData.warehouse.currency}})</sup>
                                </th>
                                <th v-if="(formData._id && currentUser.type === 'Seller') || (currentUser.type !== 'Seller')"
                                  class="px-8 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">
                                  Total 
                                </th>

                                <th
                                  class="px-8 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">
                                  Actions
                                </th>
                              </tr>
                            </thead>
                            <tbody v-for="(item, index) in formData.details" :key="index">
                              <tr>
                                <td class="px-6 py-2 whitespace-no-wrap border-b border-gray-800">
                                  <div class="text-xs leading-5 capitalize px-2 rounded-full text-gray-900">
                                    <b>{{ item.product.id }}</b>
                                  </div>
                                </td>
                                <td class="px-6 py-2 whitespace-no-wrap border-b border-gray-800">
                                  <div class="text-xs w-160px whitespace-pre-wrap leading-5 capitalize px-2 rounded-full text-gray-900">
                                    <img :alt="item.product.name" :src="item.product.picture||'https://storeino.b-cdn.net/dev/templates/images/no-image.jpeg'" class="w-16 rounded-md mx-auto"/>
                                  </div>
                                </td>
                                <td class="px-6 py-2 whitespace-no-wrap border-b border-gray-800">
                                  <div class="text-xs w-160px whitespace-pre-wrap leading-5 capitalize px-2 rounded-full text-gray-900">
                                    {{ item.product.name }}
                                  </div>
                                </td>
                                <td class="px-6 py-2 whitespace-no-wrap border-b border-gray-800">
                                  <p>
                                      <v-select  v-model="item.category" placeholder="Category ..." :options="categories"></v-select>
                                  </p>
                                </td>


                                <td class="px-6 py-2 whitespace-no-wrap border-b border-gray-800">
                                  <div class="flex space-x-6 justify-center">

                                    <button v-if="!item.variantes || (item.variantes && item.variantes.length === 0)" @click="addQuantity(index)" :class="$colors.textPrimary" class="bg-green-500 rounded-md w-6 h-6 flex items-center focus:outline-none">
                                        <span class="font-bold text-2xl text-white"><i class="material-icons mt-2">add</i></span>
                                    </button>

                                     <span :class="$colors.textPrimary" class="text-gray-700 text-sm h-6 flex items-center focus:outline-none">
                                         <input class="focus:outline-none w-12 text-center" type="text" @keypress="isNumber"  v-model="item.quantity">
                                     </span>

                                    <button v-if="!item.variantes || (item.variantes && item.variantes.length === 0)" :class="$colors.textPrimary" @click="removeQuantity(index)" class="bg-green-500 rounded-md w-6 h-6 text-white flex items-center focus:outline-none">
                                      <span class="font-bold text-2xl text-white mt-1"><i class="material-icons">remove</i></span>
                                    </button>

                                  </div>
                                  <h4 class="other-fees mt-2 variantes">Variantes (<small>facultative</small>)</h4>
                                  <table  class="table invoice-table  invoice-table-edit variantes-table w-full">
                                      <thead v-if="item.variantes && item.variantes.length>0" class="thead">
                                          <tr><th class="invoice-detail-margin">&nbsp;</th><th class="px-2 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">Nom Vrainate</th><th class="px-2 py-1 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">Quantity</th></tr>
                                      </thead>
                                      <tbody class="invoice-items">
                                          <tr class="item-row item-row-1 with-tax" v-for="(variante, inddd) in item.variantes" :key="inddd">
                                              <td  class="item-row-actions"><div class="confirm-delete-button"><button @click="removeProduct(item, index,'variante',inddd)" title="Remove Item" class="btn btn-remove"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times" class="svg-inline--fa fa-times fa-w-11 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512"><path fill="currentColor" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path></svg></button></div></td>
                                              <td data-label="Item #1" class="item-row-summary"><span class="item-row-name border border-gray-600"><div class="item-suggest"><div role="combobox" aria-haspopup="listbox" aria-owns="react-autowhatever-1" aria-expanded="false" class="react-autosuggest__container"><input type="text" v-model="variante.name" autocomplete="off" aria-autocomplete="list" aria-controls="react-autowhatever-1" class="py-2 text-xs px-2 outline-none block h-full w-full" id="invoice-item-code" placeholder="Nom variante" value=""><div id="react-autowhatever-1" role="listbox" class="react-autosuggest__suggestions-container"></div></div></div></span></td>
                                              <td data-label="Quantity" class="item-row-quantity">
                                                  <div class="w-full rounded-md inline-flex border border-gray-600">
                                                      <input type="number" v-model="variante.quantity" @keypress="isNumber"  min="0" class="ml-2 w-full focus:outline-none rounded-md bg-white text-gray-700 focus:text-gray-600 p-1">
                                                  </div>
                                              </td>
                                            
                                          </tr>
                                          <tr  class="item-row item-row-n" >
                                            <td class="item-row-actions" colspan="3"><button :class="item.variantes && item.variantes.length===0?'animation-pulse':''" id="invoice-item-add" @click="addFees('variante',index)" title="Add Item" class="btn btn-prime"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus" class="svg-inline--fa fa-plus fa-w-14 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path></svg></button></td>
                                          </tr>
                                      </tbody>
                                  </table>
                                </td>
                                <td class="px-6 py-2 whitespace-no-wrap border-b border-gray-800">
                                    <a @click="open('LinkProduct')" :class="!item.link ? 'animation-pulse' : ''" class="w-full border 
                                     relative cursor-pointer bg-gray-100 rounded-md w-8 h-10 p-2 flex items-center justify-center inline-flex ">
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244" />
                                      </svg>
                                      <sup v-if="item.link" class="bg-green-500 flex justify-center items-center right-0 mr-2 mt-2 absolute rounded-full w-4 h-4">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-3 h-3 text-white">
                                          <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                                        </svg>

                                      </sup>
                                    </a>
                                     <vue-modal-2  name="LinkProduct" :headerOptions="{ title: `Product Link` }" @on-close="CloseModal('LinkProduct')" :noFooter="true" modalSize="md">
                                  <div class="flex flex-col px-4 gap-3 justify-center items-center w-full rounded-lg py-3 mb-6">
                                      <div  class="flex w-full rounded-md  shadow">
                                        <textarea v-model="item.link" placeholder="product link ..."  min="0" class="w-full border focus:outline-none rounded-md bg-white text-gray-700 focus:text-gray-600 p-2"></textarea>
                                      </div>
                                      <button  @click="CloseModal('LinkProduct')" class="bg-black w-full flex items-center justify-center py-2 px-4 text-white font-normal mt-2 rounded-md ">
                                        save
                                      </button>
                                  </div>
                                  </vue-modal-2>
                                </td>

                                

                                <td v-if="(formData._id && currentUser.type === 'Seller') || (currentUser.type !== 'Seller')" class="px-6 py-2 whitespace-no-wrap border-b border-gray-800">
                                    <div class="w-full rounded-md inline-flex border">
                                        <div class="flex items-center font-bold text-s  w-100px justify-center border border-gray-200 w-1/4 bg-gray-100">
                                             KG
                                        </div>
                                        <input type="number" v-model="item.poids" :disabled="currentUser.type === 'Seller'?true:false" min="0" class="w-full focus:outline-none rounded-md bg-white text-gray-700 focus:text-gray-600 p-2">
                                    </div>
                                </td>


                                 <td v-if="(formData._id && currentUser.type === 'Seller') || (currentUser.type !== 'Seller')" class="px-6 py-2 whitespace-no-wrap border-b border-gray-800">
                                    <div class="w-full rounded-md inline-flex border">
                                        <div class="flex items-center font-bold text-s  w-100px justify-center border border-gray-200 w-1/4 bg-gray-100">
                                             MAD
                                        </div>
                                        <input type="number" v-model="item.priceDH" :disabled="currentUser.type === 'Seller'?true:false" min="0" class="w-full focus:outline-none rounded-md bg-white text-gray-700 focus:text-gray-600 p-2">
                                    </div>
                                </td>
                                 <td v-if="(formData._id && currentUser.type === 'Seller') || (currentUser.type !== 'Seller')" class="px-6 py-2 whitespace-no-wrap border-b border-gray-800">
                                    <div class="w-full rounded-md inline-flex border">
                                        <div class="flex items-center  font-bold text-s  w-100px justify-center border border-gray-200 w-1/4 bg-gray-100">
                                            <span v-if="formData.warehouse"> {{formData.warehouse.currency}}</span>
                                        </div>
                                        <input type="number" v-model="item.price" :disabled="currentUser.type === 'Seller'?true:false" min="0" class="w-full focus:outline-none rounded-md bg-white  text-gray-700 focus:text-gray-600 p-2">
                                    </div>
                                </td>

                                <td v-if="(formData._id && currentUser.type === 'Seller') || (currentUser.type !== 'Seller')" class="px-6 py-2 whitespace-no-wrap border-b border-gray-800">
                                    ({{item.priceDH*item.quantity}} MAD  | {{item.price*item.quantity}} <span v-if="formData.warehouse"> {{formData.warehouse.currency}}</span>)
                                </td>

                                <td class="py-4 whitespace-no-wrap border-b border-gray-200">
                                  <div class="flex justify-center">
                                    <button  :disabled="currentUser.type === 'Seller' || (formData._id && formData.status !== 'pending')  ? true : false"  @click="removeProduct(item, index,'products')" :class="$colors.actionTable"
                                      class="rounded-full flex focus:outline-none w-8 h-8 mr-3 mt-1">
                                      <i class="material-icons mx-1">delete</i>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          <h4 v-if="(formData.fees.length>0 && currentUser.type === 'Seller') || (currentUser.type !== 'Seller')" class="other-fees mt-5 third uppercase">Autres Fees</h4>

                           <div v-if="formData.details.length>0 && ((formData._id && currentUser.type === 'Seller' && formData.status !== 'pending') || (currentUser.type !== 'Seller'))" class="app-theme  invoice-item-list content-block">

                            <table v-if="(formData.fees.length>0 && currentUser.type === 'Seller') || (currentUser.type !== 'Seller')"  class="table invoice-table  invoice-table-edit">
                                 <thead class="thead" v-if="formData.fees.length>0">
                                     <tr><th v-if="currentUser.type !== 'Seller'" class="invoice-detail-margin">&nbsp;</th><th class="invoice-detail-summary bg-gray-50">Fees name</th><th class="invoice-detail-quantity bg-gray-50">Price <sup>(MAD)</sup></th><th class="invoice-detail-total bg-gray-50">Price <sup v-if="formData.warehouse">({{formData.warehouse.currency}})</sup></th></tr>
                                 </thead>
                                 <tbody class="invoice-items">
                                    <tr class="item-row item-row-1 with-tax" v-for="(item, index) in formData.fees" :key="index">
                                        <td v-if="currentUser.type !== 'Seller'"  class="item-row-actions"><div class="confirm-delete-button"><button @click="removeProduct(item, index,'fees')" title="Remove Item" class="btn btn-remove"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times" class="svg-inline--fa fa-times fa-w-11 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512"><path fill="currentColor" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path></svg></button></div></td>
                                        <td data-label="Item #1" class="item-row-summary"><span class="item-row-name border border-gray-600 p-1"><div class="item-suggest"><div role="combobox" aria-haspopup="listbox" aria-owns="react-autowhatever-1" aria-expanded="false" class="react-autosuggest__container"><input type="text" :disabled="currentUser.type === 'Seller'?true:false" v-model="item.name" autocomplete="off" aria-autocomplete="list" aria-controls="react-autowhatever-1" class="py-2 text-xs px-2 outline-none block h-full w-full" id="invoice-item-code" placeholder="Fees name" value=""><div id="react-autowhatever-1" role="listbox" class="react-autosuggest__suggestions-container"></div></div></div></span></td>
                                     
                                        <td data-label="Quantity" class="item-row-quantity">
                                             <div class="w-full rounded-md inline-flex border">
                                                <div class="flex items-center font-bold text-s  w-100px justify-center border border-gray-200 w-1/4 bg-gray-100">MAD </div>
                                                <input type="number" v-model="item.priceDH" :disabled="currentUser.type === 'Seller'?true:false" min="0" class="w-full focus:outline-none rounded-md bg-white text-gray-700 focus:text-gray-600 p-2">
                                            </div>
                                        </td>
                                        <td data-label="Amount" class="item-row-amount">
                                               <div class="w-full rounded-md inline-flex border">
                                                <div class="flex items-center  font-bold text-s  w-100px justify-center border border-gray-200 w-1/4 bg-gray-100">
                                                    <span v-if="formData.warehouse"> {{formData.warehouse.currency}}</span>
                                                </div>
                                                <input type="number" v-model="item.price" :disabled="currentUser.type === 'Seller'?true:false" min="0" class="w-full focus:outline-none rounded-md bg-white  text-gray-700 focus:text-gray-600 p-2">
                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-if="currentUser.type !== 'Seller'" class="item-row item-row-n" >
                                       <td class="item-row-actions" colspan="4"><button id="invoice-item-add" @click="addFees('fees')" title="Add Item" class="btn btn-prime"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus" class="svg-inline--fa fa-plus fa-w-14 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path></svg></button></td>
                                    </tr>
                                 </tbody>
                            </table>
                            <hr class="divider margin">
                            <div class="invoice-totals-container content-block float-right">
                               <div class="invoice-totals-row invoice-summary-subtotal desktop">
                                    <div class="invoice-summary-label font-bold uppercase">Produits amount: </div><div class="invoice-summary-value"><span class="currency"><span class="localized-number">
                                      <sup v-if="formData.warehouse">{{formData.warehouse.currency}}</sup>  {{formData.details.reduce((a,curr) => a + parseFloat(curr.price||0)*parseFloat(curr.quantity||0), 0).toFixed()}} | 
                                      <sup>MAD</sup> {{formData.details.reduce((a,curr) => a + parseFloat(curr.priceDH||0)*parseFloat(curr.quantity||0), 0).toFixed()}}
                                    </span></span></div>
                               </div>
                                <div class="invoice-totals-row invoice-summary-tax desktop">
                                    <div class="invoice-summary-label font-bold uppercase">Autres Fees: </div><div class="invoice-summary-value"><span class="currency">
                                      <span class="localized-number">
                                         <sup v-if="formData.warehouse">{{formData.warehouse.currency}}</sup> {{formData.fees.reduce((a,curr) => a + parseFloat(curr.price||0), 0).toFixed()}} |
                                         <sup>MAD</sup> {{formData.fees.reduce((a,curr) => a + parseFloat(curr.priceDH||0), 0).toFixed()}}
                                      </span>
                                    </span></div>
                                </div>
                                <div class="invoice-totals-row invoice-summary-balance invoice-balance bold desktop" data-selector="invoice-balance"><div class="invoice-summary-label font-bold uppercase">Total: </div><div class="invoice-summary-value"><span class="currency">
                                  <span class="localized-number">
                                    <sup v-if="formData.warehouse">{{formData.warehouse.currency}}</sup> {{(formData.details.reduce((a,curr) => a + parseFloat(curr.price||0)*parseFloat(curr.quantity||0), 0)+formData.fees.reduce((a,curr) => a + parseFloat(curr.price||0), 0)).toFixed()}} |
                                    <sup>MAD</sup> {{(formData.details.reduce((a,curr) => a + parseFloat(curr.priceDH||0)*parseFloat(curr.quantity||0), 0)+formData.fees.reduce((a,curr) => a + parseFloat(curr.priceDH||0), 0)).toFixed()}}
                                  </span>
                                </span></div></div>
                            </div>
                           </div>
                        </div>


                        <div v-if="formData._id && !['pending','rejected'].includes(formData.status)"  class="bg-white rounded-lg p-6">
                              <div class="grid lg:grid-cols-2 gap-6">
                                  <div :class="!formData.paymentMethod.name?'animation-pulse':''"  class="border  hover:border-green-400 focus-within:border-green-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
                                    <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                                      <p>
                                        <label for="seller" class="bg-white text-gray-700 px-1"> Méthode de payement <span :class="$colors.required">*</span></label>
                                      </p>
                                    </div>    
                                    <div>
                                        <v-select  v-model="formData.paymentMethod.name" :options="paymentMethods"></v-select>
                                       
                                         
                                            <div class="w-full mb-3 sourcings" v-if="formData.paymentMethod.name && ['virement bancaire','wise'].includes(formData.paymentMethod.name)">
                                                <div class="card p-3">
                                                    <div class="img-box">
                                                      <svg v-if="formData.paymentMethod.name === 'wise'" class="np-logo-svg np-logo-svg--size-md" xmlns="http://www.w3.org/2000/svg" width="106" height="24" fill="none"><path fill="#163300" d="M58.738.359h6.498l-3.27 23.322h-6.498L58.739.359Zm-8.193 0L46.16 13.794 44.247.359h-4.545L33.96 13.754 33.243.36h-6.299l2.193 23.322h5.223l6.458-14.75 2.272 14.75h5.143L56.725.359h-6.18Zm54.558 13.555H89.674c.08 3.03 1.894 5.023 4.565 5.023 2.014 0 3.608-1.077 4.844-3.13l5.208 2.368C102.501 21.702 98.729 24 94.08 24c-6.339 0-10.545-4.266-10.545-11.123C83.535 5.342 88.478 0 95.455 0c6.14 0 10.007 4.146 10.007 10.605 0 1.076-.12 2.152-.359 3.309Zm-5.78-4.466c0-2.71-1.516-4.425-3.947-4.425-2.512 0-4.585 1.794-5.144 4.425h9.09ZM6.632 7.387 0 15.139h11.844l1.33-3.655H8.1l3.1-3.586.01-.095-2.016-3.471h9.072l-7.032 19.35h4.812L24.538.358H2.6l4.033 7.028Zm69.168-2.364c2.292 0 4.3 1.233 6.055 3.346l.921-6.575C81.143.688 78.93 0 76 0c-5.82 0-9.09 3.409-9.09 7.734 0 3 1.675 4.834 4.426 6.02l1.315.598c2.452 1.047 3.11 1.565 3.11 2.671 0 1.146-1.106 1.874-2.79 1.874-2.782.01-5.034-1.415-6.728-3.847l-.94 6.699C67.234 23.22 69.708 24 72.97 24c5.532 0 8.93-3.19 8.93-7.615 0-3.01-1.335-4.943-4.704-6.458l-1.435-.678c-1.994-.887-2.671-1.375-2.671-2.352 0-1.056.927-1.874 2.71-1.874Z"></path></svg>
                                                      <strong v-else>RIB</strong>
                                                    </div>
                                                    <div class="number">
                                                        <label class="fw-bold" for=""> {{ formData.paymentMethod.name === 'wise'?curreSetting.wise:curreSetting.rib }}</label>
                                                    </div>      
                                                </div>
                                            </div>
                                        
                                    </div>

                                    <div v-if="formData.paymentMethod.name && ['virement bancaire','wise'].includes(formData.paymentMethod.name)" class="border hover:border-green-500 mt-5  focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
                                      <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                                        <p>
                                          <label for="Picture" class="bg-white text-gray-700 px-1"> {{$t('Virement Bancaire photo')}} <span :class="$colors.required">*</span></label>
                                        </p>
                                      </div>
                                      <!--<p>
                                        <input id="Picture" autocomplete="false" tabindex="0" type="text" v-model="formData.picture" class="py-2 text-xs px-2 outline-none block h-full w-full"/>
                                      </p>-->
                                      <div class="flex w-full mt-10 mb-10  items-center justify-center bg-grey-lighter">
                                        <label :class="!formData.paymentMethod.prof?'animation-pulse':''"  class="flex flex-col items-center px-4 py-6 bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue hover:text-white">
                                            <svg  v-if="!formData.paymentMethod.prof" class="w-8 h-8" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                                            </svg>
                                            <img v-if="formData.paymentMethod.prof" :src="formData.paymentMethod.prof" alt="" />
                                            <span v-if="!formData.paymentMethod.prof" class="mt-2 text-base leading-normal"> {{$t('select_image')}}</span>
                                            <input type='file' @change="handleImage($event)" class="hidden" accept="image/*"   />

                                            <span v-if="formData._id && !formData.paymentMethod.prof" class="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4" role="alert">
                                              <p>La taille de image doit pas dépasser 500 ko</p>
                                            </span>
                                        </label>
                                    </div>
                                  </div>
                                    
                                  </div> 
                                
                              </div>  
                          </div>     
                       

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- ************************************************************************************************-->
            </div>
            <!-- button save -->
           
            <!--end button save -->
            <!-- ***********************************Pop up Affectation products to Order************************************************ -->
             <vue-modal-2  name="Modal_products" :headerOptions="{title: 'Add Products'}" :noFooter="true" modalSize="lg" @on-close="closePopUp">
              <div class="flex p-5 pt-0  flex-col w-full h-auto mb-12">
                <!-- Header -->
                <div class="flex w-full h-auto justify-center items-center">
                  <div class="flex mb-6 w-full h-auto py-3 justify-center items-center text-2xl font-bold">
                    <span class="w-screen md:w-full h-10 cursor-pointer border border-gray-200 rounded text-sm flex">
                      <input type="search" name="search" v-model="searchText" placeholder="Search"
                        class="flex-grow px-4 rounded-l-full rounded-r-full text-sm focus:outline-none" />
                      <i class="material-icons text-green-400 m-2 mr-5 text-lg w-4 h-4">search</i>
                    </span>
                  </div>
                </div>
                <div>
                  <div v-if="products.length <= 0" class="py-2">
                    <span class="w-full flex justify-center text-center py-20 border-b border-gray-200 text-base">
                      <p class="font-semibold flex text-green-500 whitespace-no-wrap">
                        <span class="">No Products found</span>
                      </p>
                    </span>
                  </div>
                  <div v-for="(item, index) in resultSearchUsers" :key="index" :value="item._id" 
                    class="flex space-x-5 items-center justify-between h-auto py-1 px-5 rounded border border-gray-300 mb-2">
                    <span>
                      <input type="checkbox" class="form-checkbox  inline-block  text-green-500  border-blue-500"
                        @change="addProductsToTemp(item, index)" v-model="item.check" />
                   
                        <img :alt="item.name" :src="item.picture||'https://storeino.b-cdn.net/dev/templates/images/no-image.jpeg'" class="inline-block h-16 mx-4 rounded-md" />
                        <div class="inline-block">
                          <h3 class="text-gray-80">{{ item.name }}</h3>
                          <p class="text-gray-600 text-xs"> 
                            <span v-for="(images, r) in item.details.filter(it => it.warehouse && it.warehouse.is_primary)" :key="r" class="inline-block">
                                  <img :src="images.warehouse.flag" class="w-4 h-4 rounded-full mr-2">
                            </span>
                          </p>
                        </div>
                   </span>
                    <!--<div class="justify-center text-xs text-center bg-blue-100 font-semibold rounded-full text-green-500 ">
                      <p>{{ item.price }} <sup class="text-black">{{wharhouse.currency }}</sup></p>
                    </div>-->
                  </div>
                </div>
              </div>

              <div class="modal-footer px-5 border0-t text-right">
                <button @click="closePopUp"
                  class="flex text-white mx-auto max-w-sm rounded text-center bg-gray-500 py-2 px-8 items-center focus:outline-none md:float-right">
                  Cancel
                </button>
                <button @click="ConfirmOperation"
                  class="flex mr-2 text-white mx-auto max-w-sm rounded text-center bg-green-500 py-2 px-8 items-center focus:outline-none md:float-right">
                  Confirm
                </button>
              </div>

            </vue-modal-2>

            
            <!-- ******************************************************** -->
          </div>
        </div>
      </div>
     
    </div>
    <div class="pt-3 flex btn-bottom-toolbar justify-end">
      <button
        :disabled="loading"
        class="rounded focus:outline-none outline-none text-gray-100 px-3 w-1/6 py-2 bg-green-600  hover:shadow-inner hover:bg-green-700  transition-all duration-300"
        @click="save">
        {{$t('save')}}
      </button>
  </div>
  </div>
</template>

<script>
export default {
  props: {
    currentUser: { type: Object },
    idWharhouse: { type: Object },
  },
  data() {
    return {
      formData: {
        provider: {},
        details: [],
        fees: [],
        warehouse: null,
        link: null,
        seller: null,
        paymentMethod: {},
      },
      paginate: {
        total: 0,
        currentpage: 1,
        lastpage: 1,
        per_page: 0,
      },
      selectContry: null,
      searchText: "",
      products: [],
      warehouses: [],
      show_modal: true,
      productselected: [],
      numberItemRepeated: 0,
      sellers: [],
      status: [
        "pending",
        "en attente de paiement",
        "en attente de validation",
        "valide",
        "rejected",
      ],
      transports: ["road", "railway", "air", "maritime"],
      paymentMethods: ["virement bancaire", "wise", "demande de crédit"],
      categories: [
        "Électronique",
        "Vêtements",
        "Maison et jardin",
        "Alimentation et boissons",
        "Santé et beauté",
        "Sports et loisirs",
        "Automobile et moto",
        "Bébé et enfant",
        "Animaux de compagnie",
        "Électroménager",
        "Outils et bricolage",
        "Bijoux et montres",
        "Cosmétique",
      ],
      options: this.$countries.filter(item => item.name == 'Turkey' || item.name == 'Morocco'  || item.name == 'United Arab Emirates'  || item.name == 'China'  ),
      loading: false,
      fullPage: false,
      curreSetting: { rib: "", wise: "" },
    };
  },
  async mounted() {
    await this.getCurrentSeller();
    await this.dataToEdit();

    console.log('this.$countries----------------',this.$countries)
  },
  watch: {
    "formData.details": {
      handler: function (newValue) {
        var vm = this;
        vm.setValue();
        // console.log("New age: " + newValue.age)
      },
      deep: true,
    },
  },
  methods: {
    isEmpty(value) {
      return (
        value == null ||
        (typeof value === "string" && value.trim().length === 0)
      );
    },
    isNumber(val) {
      if (isNaN(Number(val.key)) || this.isEmpty(val.key)) {
        console.log("in nanisNumber-----", val.key);
        return val.preventDefault();
      }
    },
    setValue: function () {
      for (var i = 0; i < this.formData.details.length; i++) {
        console.log("in xcx-----", this.formData.details[i]);
        if (
          this.formData.details[i].variantes &&
          this.formData.details[i].variantes.length > 0
        )
          this.formData.details[i].quantity = this.formData.details[i].variantes
            .map((it) => parseInt(it.quantity) || 0)
            .reduce((prev, next) => prev + next);
        if (this.formData.details[i].quantity)
          this.formData.details[i].quantity = parseInt(
            this.formData.details[i].quantity.toString().trim().replace(".", "")
          );
      }
    },
    RouterBack: function () {
      this.$router.back();
    },
    handleImage(e) {
      const selectImage = e.target.files[0];
      if (selectImage) this.createBase64Image(selectImage);
    },
    createBase64Image(fileObjet) {
      console.log("fileObjet-------", fileObjet);
      const reader = new FileReader();
      reader.readAsDataURL(fileObjet);
      reader.onload = () => {
        console.log("reader-------", reader);
        this.formData.paymentMethod.prof = reader.result;
      };
    },
    niceBytes(x) {
      const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
      let l = 0,
        n = parseInt(x, 10) || 0;
      while (n >= 1024 && ++l) {
        n = n / 1024;
      }
      return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + units[l];
    },
    checkSize() {
      if (this.formData.paymentMethod.prof) {
        let base64Length =
          this.formData.paymentMethod.prof.length -
          (this.formData.paymentMethod.prof.indexOf(",") + 1);
        let padding =
          this.formData.paymentMethod.prof.charAt(
            this.formData.paymentMethod.prof.length - 2
          ) === "="
            ? 2
            : this.formData.paymentMethod.prof.charAt(
                this.formData.paymentMethod.prof.length - 1
              ) === "="
            ? 1
            : 0;
        let fileSize = base64Length * 0.75 - padding;
        let sizeImage = this.niceBytes(fileSize);
        let typeSize = sizeImage.split(" ")[1];
        if (typeSize === "bytes" || typeSize === "KB") {
          if (sizeImage.split(" ")[0] <= 500) return true;
          else return false;
        } else return false;
      } else return true;
    },
    async getCurrentSeller() {
      var newArray = [];
      //this.warehouses =  this.currentUser.countries;
      const res = await this.$server.search("warehouses", { limit: 100 });
      if (res && res.content.results) this.warehouses = res.content.results;

      this.currentUser.countries.forEach((arg) => {
        newArray.push(
          this.warehouses.find((x) => x.country === (arg.code || arg))
        );
      });

      this.warehouses = newArray;

      if (this.currentUser.type === "Seller")
        this.formData.seller = this.currentUser;
    },
    addFees(name, index) {
      if (name === "fees")
        this.formData.fees.push({ name: "", priceDH: 0, price: 0 });
      if (name === "variante") {
        this.formData.details[index].variantes.push({ name: "", quantity: 0 });
      }
    },
    dateMin() {
      // document.getElementById('dateexp').setAttribute('min', this.$moment(Date.now()).format('yyyy-MM-DD'));
      // if(this.currentUser.type != "Seller")  document.getElementById('dateexpar').setAttribute('min', this.$moment(Date.now()).format('yyyy-MM-DD'));
    },
    getUsersDebance: _.debounce(async function (name) {
      console.log("name----", name);
      if (this.formData.country) {
        await this.$store
          .dispatch("wharhouse/searchUser", {
            type: "Seller",
            countries: this.formData.country,
            search: name,
          })
          .then((res) => {
            this.sellers = res;
          });
      }
    }, 500),

    exceptDes(text) {
      if (text && text.length > 60) text = text.substring(0, 60) + "...";
      return text;
    },
    setSelected(contry) {
      if (contry) {
        this.formData.country = contry.name;
        this.selectContry = contry.code;
      }
    },
    async setSelectedW(warhouse) {
      this.formData.details = [];
      this.formData.sellers = [];
      if (warhouse) {
        this.formData.warehouse = warhouse;
        if (this.currentUser.type !== "Seller") await this.getSellers(warhouse);
      } else this.formData.warehouse = null;
    },
    setStatusSelected(status) {
      if (status) {
        this.formData.status = status;
      } else {
        this.formData.status = "";
      }
    },

    async getSellers(warehouse) {
      const res = await this.$server.search("users", {
        countries: warehouse.country,
        type: "Seller",
        limit: 10,
      });
      if (res.content.results) this.sellers = res.content.results;
      else this.sellers = [];
    },

    async getProducts(filters) {
      if (this.formData.seller)
        filters.seller = this.formData.seller._id || this.formData.seller;
      filters.limit = 3000;
      //filters['details.warehouse']=this.formData.warehouse._id||this.formData.warehouse
      console.log("getProducts-----", filters);
      const res = await this.$server.search("products", filters);
      if (res && res.content.results) {
        this.products = res.content.results;
        this.paginate.total = res.content.total;
        this.paginate.lastpage = res.content.last_page;
        this.paginate.per_page = res.content.per_page;
        this.paginate.currentpage = res.content.current_page;

        // for (let i in this.products) {
        //  // this.products[i].quantity = this.products[i].quantity.inStock;
        // }
      } else this.products = [];
    },
    checkingData() {
      let dataRes = null;
      for (const i in this.formData.details) {
        if (
          !this.formData.details[i].quantity ||
          parseInt(this.formData.details[i].quantity) === 0
        ) {
          dataRes = "Quantity produit obligatoire";
          return dataRes;
          break;
        }
        if (!this.formData.details[i].category) {
          dataRes = "Category produit obligatoire";
          return dataRes;
          break;
        }
        if (!this.formData.details[i].product.picture) {
          dataRes = `Image de ce produit est obligatoire: ${this.formData.details[i].product.name}`;
          return dataRes;
          break;
        }
        for (const j in this.formData.details[i].variantes) {
          if (
            !this.formData.details[i].variantes[j].quantity ||
            parseInt(this.formData.details[i].variantes[j].quantity) === 0
          ) {
            console.log(
              "11____________",
              this.formData.details[i].variantes[j]
            );
            console.log(
              "22____________",
              parseInt(this.formData.details[i].variantes[j].quantity)
            );
            dataRes = "Quantity variante obligatoire";
            return dataRes;
            break;
          }
          if (!this.formData.details[i].variantes[j].name) {
            dataRes = "Name variante obligatoire";
            return dataRes;
            break;
          }
        }
      }
      console.log("dataRes-----", dataRes);
      return dataRes;
    },
    async save() {
      if (this.formData._id) {
        console.log("formData edit-----", this.formData);

        if (
          this.formData.status === "en attente de paiement" &&
          !this.formData.paymentMethod.name &&
          this.currentUser.type === "Seller"
        ) {
          this.$toast.warning(`Méthode De Payement required`);
          return false;
        }

        if (
          ["en attente de paiement", "en attente de validation"].includes(
            this.formData.status
          ) &&
          ["virement bancaire", "wise"].includes(
            this.formData.paymentMethod.name
          ) &&
          !this.formData.paymentMethod.prof
        ) {
          console.log("Virement photo required-----");
          this.$toast.warning(`Virement photo required`);
          return false;
        }

        if (this.formData.paymentMethod.prof && !this.checkSize()) {
          this.$toast.warning(
            `la taille de image doit pas dépasser 500 ko !! `
          );
          return false;
        }

        this.formData.country = this.selectContry;

        console.log("this.formData update-----", this.formData);
        this.checkingData();
        console.log("-----oook --------");
        if (this.checkingData()) {
          this.$toast.warning(this.checkingData());
          return false;
        }
        this.formData.countryName = this.$f.getCountryNameByCode(
          this.options,
          this.formData.country
        );
        this.loading = true;
        const data = await this.$server.update("sourcings", this.formData);
        this.loading = false;
        if (data && data._id) {
          this.$toast.success("Sourcing updated");
          this.formData.country = this.$f.getCountryNameByCode(
            this.options,
            this.selectContry
          );
          await this.dataToEdit();
        } else this.$toast.warning(data);
      } else {
        console.log("this.formData-----------", this.formData);
        if (this.checkData()) {
          if (this.checkingData()) {
            this.$toast.warning(this.checkingData());
            return false;
          }

          this.formData.country = this.selectContry;
          this.formData.countryName = this.$f.getCountryNameByCode(
            this.options,
            this.formData.country
          );
          console.log("this.formDat  44444-----------");
          this.loading = true;
          const data = await this.$server.create("sourcings", this.formData);
          console.log("this.formDat  5555-----------");
          this.loading = false;
          if (data && data._id) {
            this.selectContry = null;
            this.formData = {
              provider: {},
              details: [],
              fees: [],
            };
            if (this.currentUser.type == "Seller")
              this.formData.seller = this.currentUser;
            this.$toast.success("Sourcing created");
          } else this.$toast.warning(data);
        } else this.$toast.warning(`you must fill all the fields !!`);
      }
    },
    getQuantity() {
      this.formData.quantity = 0;
      for (let i = 0; i < this.formData.details.length; i++) {
        this.formData.quantity += this.formData.details[i].quantity;
      }
    },
    checkData() {
      if (
        this.formData.warehouse &&
        this.formData.country &&
        this.formData.details.length > 0 &&
        this.formData.provider.transportMode &&
        this.formData.seller )
        return true;
      else return false;
    },
    removeProduct(data, index, tab, sousIndex) {
      if (tab === "products") {
        this.$confirm("Are you sure you want to delete this product?").then(
          async (res) => {
            var index = this.formData.details.findIndex(function (o) {
              return o.product._id === data.product._id;
            });
            if (index !== -1) this.formData.details.splice(index, 1);
            data.product.check = false;
            this.$toast.success("Product deleted");
          }
        );
      }

      if (tab === "fees") this.formData.fees.splice(index, 1);

      if (tab === "variante")
        this.formData.details[index].variantes.splice(sousIndex, 1);
    },

    addProductsToTemp(data, index) {
      if (data.check) {
        const detail = {
          product: data,
          quantity: 1,
          priceDH: 0,
          price: 0,
          variantes: [],
        };

        let found = 0;
        for (let i = 0; i < this.formData.details.length; i++) {
          if (this.formData.details[i].product._id == data._id) {
            found = found + 1;
            this.numberItemRepeated = this.numberItemRepeated + 1;
          }
        }
        if (found >= 1) {
        } else {
          this.productselected.push(detail);
        }
      } else {
        let pos = this.productselected
          .map(function (e) {
            return e.product._id;
          })
          .indexOf(data._id);
        this.productselected.splice(pos, 1);
      }
    },

    addQuantity(index) {
      this.formData.details[index].quantity++;
    },
    removeQuantity(index) {
      if (this.formData.details[index].quantity <= 1) {
      } else {
        this.formData.details[index].quantity--;
      }
    },
    ViderTable() {
      this.productselected = [];
    },
    ViderProductTable() {
      this.formData.details = [];
    },
    async OpenPopUp() {
      if (this.formData.seller && this.formData.warehouse) {
        // await this.getProducts({'details.warehouse':this.formData.warehouse._id||this.formData.warehouse});
        await this.getProducts({});
        if (this.products.length > 0) {
          this.$vm2.open("Modal_products");
        } else {
          if (this.currentUser.type != "Seller")
            this.$toast.warning(
              `There are no products for the selected seller`
            );
          else this.$toast.warning(`You haven't added any product yet`);
        }
      }
    },
      async CloseModal(id) {
            this.$vm2.close(id);
        },
        async open(name) {
            this.$vm2.open(name);
        },
    ConfirmOperation() {
      let fount = 0;
      if (this.numberItemRepeated > 0) {
        this.$toast.success("There are many products already added!");
      } else {
        for (let i = 0; i < this.productselected.length; i++) {
          this.formData.details.push(this.productselected[i]);
        }
      }
      this.productselected = [];

      this.numberItemRepeated = 0;
      this.$vm2.close("Modal_products");
    },
    closePopUp() {
      this.$vm2.close("Modal_products");
    },
    async dataToEdit() {
      if (this.$route.params.id) {
        const editData = await this.$server.get("sourcings", {
          id: this.$route.params.id,
        });
        this.formData = editData.content;
        if (!this.formData.fees) this.formData.fees = [];
        this.selectContry = this.formData.country;
        this.formData.country = this.$f.getCountryNameByCode(
          this.options,
          this.formData.country
        );
        if (!this.formData.paymentMethod)
          this.formData.paymentMethod = { name: "", prof: "" };
        if (this.formData.status === "pending")
          this.status = ["pending", "rejected"];
        else
          this.status = [
            "pending",
            "en attente de paiement",
            "en attente de validation",
            "valide",
            "rejected",
          ];
        //this.formData.facturationDate = this.$moment(this.formData.date).format('yyyy-MM-DDTHH:mm');
        if (
          ["en attente de paiement", "en attente de validation"].includes(
            this.formData.status
          )
        ) {
          const { content: Settingdata } = await this.$server.get("settings");
          this.curreSetting = Settingdata.paymentMethods || {
            rib: "",
            wise: "",
          };
        }
      }
    },
  },

  computed: {
    resultSearchUsers() {
      if (this.searchText) {
        return this.products.filter((product) => {
          var regex = new RegExp(this.searchText, "i");

          return regex.test(product.name);
        });
      } else {
        return this.products;
      }
    },
  },

  /************************************* Pagination **************************************************/
};
</script>

<style scoped>
.sourcings .card {
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: #cfe7ff;
  border-radius: 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.sourcings .card .img-box {
  width: 80px;
  height: 35px;
}
.sourcings .card img {
  width: 100%;
  object-fit: fill;
  vertical-align: middle;
}
.sourcings .card .number {
  font-size: 20px;
}
.fw-bold {
  font-weight: 700 !important;
}

.third {
  text-decoration: underline overline limegreen double 1.2px;
  font-size: 1.3em;
  text-underline-offset: 10px;
  margin-bottom: 10px;
}
.btn-bottom-toolbar {
  margin-left: -15px;
}
.vs__dropdown-toggle {
  border: none;
}
.text-s {
  font-size: 12px;
}
.w-100px {
  width: 90px;
}
.w-130px {
  width: 130px;
}
.w-160px {
  max-width: 160px;
}
.modal-header {
  background: #226faa;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#226faa),
    color-stop(37%, #2989d8),
    to(#72c0d3)
  );
  background: linear-gradient(to right, #226faa 0, #2989d8 37%, #72c0d3 100%);
  border-radius: 6px;
  color: #fff;
  padding: 18px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-color: transparent;
  border-bottom: 1px solid #e5e5e5;
}

.table.item thead {
  background: #3182ce4f;
  color: #fff;
  border: 0;
}

.toggle__dot {
  transition: all 0.3s ease-in-out;
}

.seller-select .vs__dropdown-toggle {
  border: none;
}

input:checked ~ .toggle__dot {
  transform: translateX(100%);
  background-color: #68d391;
}

/* animation for vue transition tag */
dialog[open] {
  animation: appear 0.15s cubic-bezier(0, 1.8, 1, 1.8);
}

dialog::backdrop {
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.5), rgba(54, 54, 54, 0.5));
  backdrop-filter: blur(3px);
}

@keyframes appear {
  from {
    opacity: 0;
    transform: translateX(-3rem);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.toggle__dot {
  top: -0.25rem;
  left: -0.25rem;
  transition: all 0.3s ease-in-out;
}

input:checked ~ .toggle__dot {
  transform: translateX(100%);
  background-color: #63b3ed;
}
</style>
